import React from "react"
import { Layout } from 'antd';
import "./layout.css";


const dt = new Date();
const { Footer } = Layout;
const LayoutApp = ({ children }) => {
  return (
    <div>
      <div>{children}</div>
      <Footer style={{ textAlign: 'center', background: '#eab300' }}><span style={{ color: '#fff' }}>Puratos Italia S.r.l. © {dt.getFullYear()} Design by <a style={{ color: '#000' }} href="http://www.teknomaint.it" rel="noreferrer" target="_blank">Teknomaint Sistemi Informatici S.r.l.</a></span></Footer>
    </div>
  )
}


export default LayoutApp
